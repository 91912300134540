.flex {
    display: flex;
}

.order-1 {
    order: 1;
}

.w-5\/12 {
    width: 41.666667%;
}

.mb-3 {
    margin-bottom: 0.75rem;
}

.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.font-bold {
    font-weight: bold;
}

.leading-snug {
    line-height: 1.375;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.roadmap-entry {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    width: 100%;
}

.roadmap-entry a {
    color: #22d3ee;
}