:root {
  
  /* Main Settings  */
  --main-background-color: #000;

  --text-primary-color: #f0f9ff;
  --text-secondary-color: #bae6fd;

  --text-accent-color: #7dd3fc;

  /* Nav Settings  */
  --nav-link-color: #F0F9FF;
  --nav-link-hover-color: #22d3ee;
  --nav-link-hover-background-color: none;
  --nav-background-color: none;
  --nav-height: 150px;
  --nav-logo-width: 125px;
  --nav-logo-font-size: 16px;

  /* Mobile Nav Settings  */

  --mobile-nav-background-color: #0D0D0D;
  --mobile-nav-logo-height:100px;
  --mobile-nav-link-color: #7dd3fc;
  --mobile-nav-link-hover-color: #22d3ee;
  --mobile-nav-link-size: 20px;

  /* Minting Area */
  --minting-box-color: #0D0D0D;
  --minting-box-heading-color: #22d3ee;
  --minting-box-info-box-color: none;
  --minting-box-text-color: #f0f9ff;

}






