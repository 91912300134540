.faq-entry {
    padding-top: 1.5rem;
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;

    text-align: left;
}

.faq-entry-question button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    justify-items: start;
    text-align: left;


    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;

    background-color: transparent;
    color: #f0f9ff;
    border: none;

    cursor: pointer;
}

.faq-entry-question-svg {
    display: flex;
    justify-items: center;
    height: 1.75rem;
    margin-left: 1.5rem;
}

.faq-entry-question-svg svg {
    height: 1.5rem;
    width: 1.5rem;
}

.faq-entry-answer {
    overflow: hidden;
    padding-right: 1.5rem;
    padding-left: 0.5rem;
    margin-top: 0.5rem;

    font-size: 1rem;
    line-height: 1.5rem;

    font-weight: 300;
    color: #d4d4d8;

    transition-property: max-height;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
}

.faq-entry-answer a {
    color: var(--nav-link-hover-color);
}

hr {
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;

    background-color: #0D0D0D;
    border: none;
    height: 1px;
}